/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


        $('.ajax-loader').append('<svg aria-hidden="true" data-prefix="fas" data-icon="sync-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="ml-3 svg-inline--fa fa-sync-alt fa-w-16 fa-spin"><path fill="currentColor" d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z" class=""></path></svg>');

        var createCookie = function(name, value, days) {
          var expires;
          if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
          } else {
            expires = "";
          }
          document.cookie = name + "=" + value + expires + "; path=/";
        };

        var readCookie = function(name) {
          var nameEQ = name + "=";
          var ca = document.cookie.split(';');
          for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
              c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
              return c.substring(nameEQ.length, c.length);
            }
          }
          return null;
        };

        var eraseCookie = function(name) {
          createCookie(name, "", -1);
        };

        y = readCookie('contrast');
        highContrastOn = 'Switch To Standard color contrast version of the website';
        highContrastOff = 'Switch to High color contrast version of the website';
        if (y === 'true') {
          $("body").addClass("contrastHigh");
          $("#styleSwitcher").attr('title', highContrastOn).attr('aria-label', highContrastOn);
        }

        document.getElementById("styleSwitcher").onclick = function(event) {
          y = readCookie('contrast');
          if (y === 'true') {
            $("body").removeClass("contrastHigh");
            $("#styleSwitcher").attr('data-original-title', highContrastOff).attr('aria-label', highContrastOff);
            var id = $("#styleSwitcher").attr('aria-describedby');
            $("#" + id + " .tooltip-inner").text(highContrastOff);
            createCookie('contrast', 'false', 30);
          } else {
            $("body").addClass("contrastHigh");
            $("#styleSwitcher").attr('data-original-title', highContrastOn).attr('aria-label', highContrastOn);
            var id = $("#styleSwitcher").attr('aria-describedby');
            $("#" + id + " .tooltip-inner").text(highContrastOn);
            createCookie('contrast', 'true', 30);
          }
        };

        var wcagTooltip = function() {

          var isEsc = false;

          $('[data-toggle="tooltip"]').tooltip({
            delay: {
              "show": 0,
              "hide": 5500
            }
          });

          $('[data-toggle="tooltip"]').on('mouseenter mouseleave', function() {
            $(this).toggleClass('hovered');
          });

          $('[data-toggle="tooltip"]').on('show.bs.tooltip', function() {
            $('[data-toggle="tooltip"]').tooltip('hide');
          });

          $('[data-toggle="tooltip"]').on('hide.bs.tooltip', function() {
            var tooltip = $('.tooltip');
            var currentTooltip = $(this);

            if ($('.tooltip:hover').length > 0 && isEsc === false) {
              tooltip.on('mouseleave', function(e) {
                setTimeout(function() {
                  if (tooltip.length > 0 && $('.tooltip:hover').length <= 0 && !currentTooltip.hasClass('hovered')) {
                    currentTooltip.tooltip('hide');
                  }
                }, 501);
              });
              return false;
            }
          });

          $(document).on('keydown', function(e) {
            if (e.keyCode === 27) {
              isEsc = true;
              $('[data-toggle="tooltip"]').tooltip('hide');
            }
          });

          $(document).on('keyup', function(e) {
            if (e.keyCode === 27) {
              isEsc = false;
            }
          });
        };
        wcagTooltip();
        $('[data-toggle="tooltip"]').tooltip();

        var navBarExpanded = false;
        var fixedTop = false;
        var fixedbottom = false;

        var originalBodyPad;
        var measureScrollbar = function() {
          var scrollDiv = document.createElement('div');
          scrollDiv.className = 'menu-scrollbar-measure';
          $('body').append(scrollDiv);
          var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
          scrollDiv.remove();
          return scrollbarWidth;
        };

        var toggleNavbar = function() {
          var scrollbarWidth = measureScrollbar();
          if (!navBarExpanded) {
            var topBarHeight = $(".top-bar").outerHeight();
            var anavbarHeight = $(".navbarHeight").outerHeight();
            if (!fixedTop) {
              $("#navbarSupportedContent").css("max-height", "calc( 100vh - " + topBarHeight + "px - " + anavbarHeight + "px )");
            } else {
              $("#navbarSupportedContent").css("max-height", "calc( 100vh - " + anavbarHeight + "px )");
            }
            $(".offNav").fadeToggle();
            $(".padd-me").css("margin-right", scrollbarWidth);
            $("body").css("margin-right", scrollbarWidth);
            $(".marg-me").css("margin-right", scrollbarWidth);
            $(".right-me").css("margin-right", -scrollbarWidth);
            $("body").css("overflow", "hidden");
          } else {
            $(".offNav").fadeToggle();
            $("#navbarSupportedContent").css("max-height", "none");
            $(".padd-me").css("margin-right", "0");
            $("body").css("margin-right", "0");
            $(".marg-me").css("margin-right", "0");
            $(".right-me").css("margin-right", "0");
            $("body").css("overflow", "auto");
          }
          navBarExpanded = !navBarExpanded;
        };

        $(".offNav").on("click", function() {
          toggleNavbar();
        });

        $(".navbar-toggler").on("click", function() {
          toggleNavbar();
        });

        $(".TEST").on("click", function(e) {
          e.preventDefault();
          $('#navbarSupportedContent').slideToggle();
        });


        // JavaScript to be fired on all pages
        var navTop = {
          position: null
        };
        var navBottom = {
          position: null
        };

        var fixedNav = function(nav, placement, navObject, marginObject) {

          if (placement === 'top') {
            if (navObject.position === null) {
              navObject.position = nav.position().top;
            }
            if ($(window).scrollTop() >= navObject.position) {
              nav.addClass('fixed-top');
              if (marginObject !== undefined && !marginObject.hasClass('margin-adjusted')) {
                marginObject.css('margin-bottom', nav.outerHeight()).addClass('margin-adjusted');
                fixedTop = true;
              }
            } else {
              nav.removeClass('fixed-top');
              if (marginObject !== undefined && marginObject.hasClass('margin-adjusted')) {
                marginObject.removeAttr('style').removeClass('margin-adjusted');
                fixedTop = false;
              }
            }
          } else {
            if (navObject.position === null) {
              navObject.position = nav.position().top + nav.outerHeight();
            }
            if ($(window).scrollTop() + $(window).height() >= navObject.position) {
              nav.addClass('fixed-bottom');
              if (marginObject !== undefined && !marginObject.hasClass('margin-adjusted')) {
                marginObject.css('margin-bottom', nav.outerHeight()).addClass('margin-adjusted');
                fixedBottom = true;
              }
            } else {
              nav.removeClass('fixed-bottom');
              if (marginObject !== undefined && marginObject.hasClass('margin-adjusted')) {
                marginObject.removeAttr('style').removeClass('margin-adjusted');
                fixedBottom = false;
              }
            }
          }
        };

        var resetFixedNav = function(nav, placement, navObject, marginObject) {
          nav.removeClass('fixed-' + placement);
          navObject.position = null;
          if (marginObject !== undefined) {
            marginObject.removeClass('margin-adjusted');
          }
          fixedNav(nav, placement, navObject, marginObject);
        };


        function getRootElementFontSize() {
          return parseFloat(
            getComputedStyle(
              document.documentElement
            ).fontSize
          );
        }

        function convertRem(value) {
          return value * getRootElementFontSize();
        }
        var windowHeight = window.innerHeight;
        var contactHeight = $(".contact-sticky").outerHeight();
        var navbarHeight = $(".navbarHeight").outerHeight();

        if (contactHeight < (windowHeight - navbarHeight - convertRem(3)) && window.innerWidth > 991) {
        maxHeight = windowHeight - convertRem(3) - navbarHeight;
          $(".contact-sticky").css( { "overflowY": "auto", "max-height": maxHeight }).stick_in_parent({
            offset_top: navbarHeight + convertRem(3)
          });

        }

        fixedNav($('.header-sticky'), 'top', navTop, $('header.banner'));

        $(window).load(function() {
          resetFixedNav($('.header-sticky'), 'top', navTop, $('header.banner'));
          $(".badges iframe").attr('title', 'Google Partner Badge');
        });

        var ctaTopDistance = $(".header-image-full .btn-danger").offset().top - navbarHeight + $(".header-image-full .btn-danger").outerHeight();

        $(window).scroll(function() {
          var scrollPos = $(window).scrollTop();
          if (scrollPos > ctaTopDistance) {
            $(".contact-sticky .btn").addClass('btn-danger');
          } else {
            $(".contact-sticky .btn").removeClass('btn-danger');
          }
          fixedNav($('.header-sticky'), 'top', navTop, $('header.banner'));
        });

        var resizeTimer;

        $(window).resize(function() {
          clearTimeout(resizeTimer);
          resizeTimer = setTimeout(function() {
            resetFixedNav($('.header-sticky'), 'top', navTop, $('header.banner'));
          }, 300);
        });

        $('#navbarSupportedContent a').last().keydown(function(e) {
          if (window.innerWidth < 992 && e.keyCode === 9 && !e.shiftKey) {
            e.preventDefault();
            $('.navbar-toggler').focus();
          }
        });
        $('.navbar-toggler').keydown(function(e) {
          if (window.innerWidth < 992 && navBarExpanded === true && e.keyCode === 9 && e.shiftKey) {
            e.preventDefault();
            $('#navbarSupportedContent a').last().focus();
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
